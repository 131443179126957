import {HourlyCapacityUsage, OverageState} from '@/api/capacities';

const DEFAULT_HOURLY_CAPACITY_USAGE: HourlyCapacityUsage = {
    aggregateStartTime: '',
    assignedCapacity: 0,
    overageLimit: 0,
    overageSCUUsed: 0,
    provisionedSCUUsed: 0,
    unbilledSCUUsed: 0,
    usedCapacity: 0,
    maxAllowedCapacity: 0,
};

/**
 * Appends intermediate spike elements to the provided hourly capacity usage data.
 * This function processes the data to detect changes in provisioning capacity, overage limit,
 * and overage state, and inserts intermediate elements to reflect these changes.
 *
 * @param {HourlyCapacityUsage[]} data - The array of hourly capacity usage data.
 * @returns {HourlyCapacityUsage[]} - The updated array with intermediate spike elements appended.
 *
 * @remarks
 * - If the data array has one or fewer elements, it is returned as is.
 * - The function uses several helper functions to detect changes in assigned capacity, overage limit,
 *   and overage state.
 * - If any change is detected and the next element's used capacity is greater than 0, an intermediate
 *   spike element is appended to the result.
 * - The intermediate spike element's `aggregateStartTime` is set to 1 second before
 *   the next element's `aggregateStartTime`.
 */
export const appendIntermediateSpikeElements = (data: HourlyCapacityUsage[]) => {
    if (data.length <= 1) {
        return data;
    }

    let nextProvisioningCapacity: number | null = null;
    let nextOverageLimit: number | null = null;
    let nextOverageState: OverageState | null = null;
    let changeDetected = false;
    let isTraversingUnlimitedOverage = false;

    let updatedResult = data.reduce((result, currentElement, i) => {
        if (i === data.length - 1) {
            result.push(currentElement);
            return result;
        }

        const nextElement = data[i + 1];
        result.push(currentElement);

        let newElement: HourlyCapacityUsage = {...DEFAULT_HOURLY_CAPACITY_USAGE};

        // Check if unlimited overage is being traversed and update the next overage state
        const {
            isTraversingUnlimitedOverage: updatedIsTraversingUnlimitedOverage,
            nextOverageState: updatedNextOverageState,
        } = checkUnlimitedOverageState(currentElement, nextElement, isTraversingUnlimitedOverage);

        isTraversingUnlimitedOverage = updatedIsTraversingUnlimitedOverage;
        nextOverageState = updatedNextOverageState;

        // Check if assigned capacity has changed and update the next provisioning capacity
        const {
            changeDetected: assignedCapacityChangeDetected,
            nextProvisioningCapacity: updatedNextProvisioningCapacity,
        } = checkAssignedCapacityChange(currentElement, nextElement);

        if (assignedCapacityChangeDetected) {
            changeDetected = true;
            nextProvisioningCapacity = updatedNextProvisioningCapacity;
        }

        // Check if overage limit has changed and update the next overage limit
        const {
            changeDetected: overageLimitChangeDetected,
            nextOverageLimit: updatedNextOverageLimit,
        } = checkOverageLimitChange(currentElement, nextElement);

        if (overageLimitChangeDetected) {
            changeDetected = true;
            nextOverageLimit = updatedNextOverageLimit;
        }

        // Check if overage state has changed and update the next overage state
        if (currentElement.overageState !== nextElement.overageState) {
            changeDetected = true;
            nextOverageState = currentElement.overageState as OverageState;
        }

        // If any change is detected, append the intermediate spike element
        if (changeDetected && nextElement.usedCapacity > 0) {
            const aggregateStartTime = new Date(currentElement.aggregateStartTime);

            newElement.assignedCapacity =
                nextProvisioningCapacity ?? currentElement.assignedCapacity;
            newElement.overageLimit = nextOverageLimit ?? currentElement.overageLimit;
            newElement.overageState = nextOverageState ?? currentElement.overageState;

            aggregateStartTime.setMinutes(
                aggregateStartTime.getMinutes() + 59,
                aggregateStartTime.getSeconds() + 59,
            );

            result.push({
                ...newElement,
                aggregateStartTime: aggregateStartTime.toISOString(),
                overageInBillingPeriod: 0,
            });

            nextProvisioningCapacity = null;
            nextOverageLimit = null;
            nextOverageState = null;
            changeDetected = false;
        }

        return result;
    }, [] as HourlyCapacityUsage[]);

    return updatedResult;
};

/**
 * Detects if the usage data traverses an unlimited overage state.
 *
 * @param {HourlyCapacityUsage[]} data - The array of hourly capacity usage data.
 * @returns {HourlyCapacityUsage[]} - The updated array with the `doesUsageTraverseUnlimitedOverage` flag set.
 *
 * @remarks
 * - The function iterates over the data and sets the `doesUsageTraverseUnlimitedOverage` flag to `true`
 *   for the elements that traverse an unlimited overage state.
 * - The flag is set to `true` for the first element that transitions from a non-unlimited overage state
 *   to an unlimited overage state.
 * - The flag is set to `true` for all elements that follow the first element until the next element
 *   transitions from an unlimited overage state to a non-unlimited overage state.
 */
export const detectUnlimitedOverageTraversal = (
    data: HourlyCapacityUsage[],
): HourlyCapacityUsage[] => {
    let isUnlimitedTraversalInProgress = false;
    for (var i = 0; i < data.length; i++) {
        let currentElement = data[i];
        currentElement.doesUsageTraverseUnlimitedOverage = false;

        if (
            !isUnlimitedTraversalInProgress &&
            currentElement.overageState === OverageState.Unlimited
        ) {
            currentElement.doesUsageTraverseUnlimitedOverage = true;
        }

        if (
            isUnlimitedTraversalInProgress &&
            currentElement.overageState !== OverageState.Unlimited &&
            currentElement.overageLimit > 0
        ) {
            currentElement.doesUsageTraverseUnlimitedOverage = true;
            isUnlimitedTraversalInProgress = false;
        }
    }

    return data;
};

const checkUnlimitedOverageState = (
    currentElement: HourlyCapacityUsage,
    nextElement: HourlyCapacityUsage,
    isTraversingUnlimitedOverage: boolean,
): {isTraversingUnlimitedOverage: boolean; nextOverageState: OverageState | null} => {
    let nextOverageState: OverageState | null = null;

    if (
        currentElement.overageState !== OverageState.Unlimited &&
        nextElement.overageState === OverageState.Unlimited
    ) {
        isTraversingUnlimitedOverage = true;
    } else if (
        isTraversingUnlimitedOverage === true &&
        nextElement.overageState !== OverageState.Unlimited
    ) {
        isTraversingUnlimitedOverage = false;
        nextOverageState = nextElement.overageState as OverageState;
    }

    return {isTraversingUnlimitedOverage, nextOverageState};
};

const checkAssignedCapacityChange = (
    currentElement: HourlyCapacityUsage,
    nextElement: HourlyCapacityUsage,
): {changeDetected: boolean; nextProvisioningCapacity: number | null} => {
    let changeDetected = false;
    let nextProvisioningCapacity: number | null = null;

    if (currentElement.assignedCapacity !== nextElement.assignedCapacity) {
        changeDetected = true;

        let assignedCapacityComparator =
            currentElement.assignedCapacity < nextElement.assignedCapacity ? Math.min : Math.max;
        nextProvisioningCapacity = assignedCapacityComparator(
            currentElement.assignedCapacity,
            nextElement.assignedCapacity,
        );
    }

    return {changeDetected, nextProvisioningCapacity};
};

const checkOverageLimitChange = (
    currentElement: HourlyCapacityUsage,
    nextElement: HourlyCapacityUsage,
): {changeDetected: boolean; nextOverageLimit: number | null} => {
    let changeDetected = false;
    let nextOverageLimit: number | null = null;

    if (currentElement.overageLimit !== nextElement.overageLimit) {
        changeDetected = true;

        let overageLimitComparator =
            currentElement.overageLimit < nextElement.overageLimit ? Math.min : Math.max;
        nextOverageLimit = overageLimitComparator(
            currentElement.overageLimit,
            nextElement.overageLimit,
        );
    }

    return {changeDetected, nextOverageLimit};
};
