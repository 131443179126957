import {
    DialogContent,
    DialogActions,
    DialogTrigger,
    Button,
    Link,
} from '@fluentui/react-components';
import React, {useEffect, useRef, useState} from 'react';
import {WorkspaceFormProps} from './WorkspaceForm.types';
import useClasses from './WorkspaceForm.styles';
import CapacitySelectField from './CapacitySelect';
import StorageLocation from './StorageLocationField';
import WorkspaceName from './WorkspaceNameField';
import ModelImprovement from './ModelImprovementField';
import ProductImprovement from './ProductImprovementField';
import TermsConditions from './TermsConditionsField';
import {useTranslation} from 'react-i18next';
import {
    useGetWorkspaces,
    useGetWorkspaceSettings,
    usePutWorkspace,
    Workspace,
} from '@/api/workspaces';
import {CreateGeoWorkspaceRequest, WorkspaceSettings} from '@/api/workspaces/workspaces.types';
import {useFeatureFlag, useUserState} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useCreateWorkspaceSettings from '@/api/workspaces/useCreateWorkspaceSettings';
import {FormErrorMessageBar} from './FormErrorMessageBar';
import useCheckPermission from '@/api/utils/useCheckArmPermissions';
import {useMsalUserId} from '@/util/msal';
import CreateCapacity from '@/components/CreateCapacity';
import useCreateWorkspace from '@/api/workspaces/useCreateWorkspace';
import WorkspacesLoadingOverlay from '../ManageWorkspaces/WorkspacesLoadingOverlay';
import {useGetAccount} from '@/api/accounts';

function CreateEdit(props: WorkspaceFormProps) {
    const {
        mode,
        onCancel,
        onClose,
        onDelete,
        onCreate,
        workspace,
        hideCapacitySelectField: hideCapacitySelectField,
        onWorkspaceCreated,
    } = props;
    const classes = useClasses();
    const handleCreateWorkspace = useCreateWorkspace();
    const {t} = useTranslation();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    // field state values
    const [workspaceName, setWorkspaceName] = useState(workspace?.name || '');
    const [dataStorageLocation, setDataStorageLocation] = useState('');
    const [capacity, setCapacity] = useState('');
    const [modelImprovement, setModelImprovement] = useState(true);
    const [productImprovement, setProductImprovement] = useState(true);
    const [termsConditions, setTermsConditions] = useState(false);
    const [isMessageBarVisible, setIsMessageBarVisible] = useState(false);
    // multi-workspaces
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: workspaceCfS, isFetched: workspacesCfSFetched} = useGetWorkspaceSettings(
        workspace,
        {
            enabled: Boolean(isWorkspacesTestingEnabled),
        },
    );
    const {mutate: createWorkspaceSettings} = useCreateWorkspaceSettings();

    const {mutate: createWorkspace, isLoading, isError, isSuccess} = usePutWorkspace();
    const handleCapacityCreated = (newCapacityName: string) => {
        setCapacity(newCapacityName);
        console.log('new capacity created: ', newCapacityName);
    };
    const {
        data: accountResponse,
        error: accountError,
        isLoading: accountLoading,
        isFetched: accountFetched,
        isSuccess: accountIsSuccessful,
        isError: accountIsError,
    } = useGetAccount();
    useEffect(() => {
        // if we are in duplicate mode, we should populate the fields with the workspace data
        if (mode === 'duplicate' && workspaceCfS) {
            console.log('prefilling data', workspaceCfS);
            // prefill the fields with the workspace data
            // for data storage location:
            // call GET /account API, it will provide list of all pods with their data storage locations in the "geo" field
            // you can match the podIds to retrieve the data storage location
            if (accountFetched) {
                accountResponse?.pods?.forEach((pod) => {
                    if (pod.podId === workspace?.path?.split('/')[1]) {
                        console.log('Data storage location: ', pod.geo);
                        //setDataStorageLocation(pod.geo);
                    }
                });
            }
            if (workspacesCfSFetched) {
                setModelImprovement(
                    workspaceCfS?.freRequirements?.allowModelImprovement ? true : false,
                );
                setProductImprovement(
                    workspaceCfS?.freRequirements?.allowProductImprovement ? true : false,
                );
            }
        }
    }, []);

    const {data: workspacesData, error: workspaceError} = useGetWorkspaces();
    const {tenant: userTenant} = useUserState();
    const [overlay, setOverlay] = useState<boolean>(false);

    const handleFormSubmit = async () => {
        setOverlay(true);
        try {
            // first check if capacity selected is already in use
            const attachedWorkspace = workspacesData?.value.find(
                (workspace: Workspace) => workspace.capacity?.referenceName === capacity,
            );
            if (attachedWorkspace) {
                // remove capacity from attached workspace using putWorkspace call
                createWorkspace(
                    {
                        request: {
                            ...attachedWorkspace,
                            name: attachedWorkspace.name,
                            capacity: null,
                        },
                        targetWorkspace: attachedWorkspace,
                    },
                    {
                        onSuccess: (data) => {
                            console.log(
                                'Successfully disconnected capacity ' +
                                    capacity +
                                    ' from ' +
                                    attachedWorkspace?.name +
                                    ' workspace',
                            );
                        },
                        onError: () => {
                            console.log(
                                'Error disconnecting capacity ' +
                                    capacity +
                                    ' from ' +
                                    attachedWorkspace?.name +
                                    ' workspace',
                            );
                            setOverlay(false);
                        },
                    },
                );
            }
            // create new workspace
            let workspace: CreateGeoWorkspaceRequest;
            if (hideCapacitySelectField) {
                // omit capacity
                workspace = {
                    name: workspaceName,
                    dataStorageLocation: dataStorageLocation,
                    workspaceOptInConfig: {
                        isAllowModelImprovement: 'false',
                        isAllowProductImprovement: 'false',
                    },
                };
            } else {
                workspace = {
                    name: workspaceName,
                    capacity: {type: 'CapacityReference', referenceName: capacity},
                    dataStorageLocation: dataStorageLocation,
                    workspaceOptInConfig: {
                        isAllowModelImprovement: 'false',
                        isAllowProductImprovement: 'false',
                    },
                };
            }
            const newWorkspace = (await handleCreateWorkspace(workspace)) as Workspace;
            // create workspace settings
            const workspaceCfS: WorkspaceSettings = {
                workspaceId: workspaceName,
                tenantId: userTenant?.tenantId,
                freRequirements: {
                    allowModelImprovement: modelImprovement,
                    allowProductImprovement: productImprovement,
                },
            };
            createWorkspaceSettings(
                {
                    workspaceSettings: workspaceCfS,
                    targetWorkspace: newWorkspace,
                },
                {
                    onSuccess: () => {
                        console.log('Workspace settings created');
                        onClose?.();
                        setIsMessageBarVisible(false);
                        setOverlay(false);
                        onCreate?.();
                    },
                    onError: (error) => {
                        console.error('Error creating workspace settings', error);
                        setOverlay(false);
                        setIsMessageBarVisible(true);
                    },
                },
            );
            onWorkspaceCreated?.(workspaceName);
            console.log('workspace name passed back to create capacity: ', workspaceName);
        } catch (error) {
            console.error('Error creating workspace', error);
            setIsMessageBarVisible(true);
        }
    };

    const primaryButtonLabel =
        mode === 'create' ? tCommon('ButtonLabels.Create') : tCommon('ButtonLabels.Duplicate');

    // get the principal id
    const principalId = useMsalUserId();
    const [subscriptionId, setSubscriptionId] = useState<string>('');
    const [scopeResource, setScopeResource] = useState<string>('');
    const {hasPermission, error, loading} = useCheckPermission({
        subscriptionId: subscriptionId, // id from capacity
        scope: scopeResource, // entire path
        principalId: principalId || '',
        requiredRoles: ['Contributor', 'Owner'],
    });
    // create capacity
    const [isCreateCapacityDialogOpen, setIsCreateCapacityDialogOpen] = useState<boolean>(false);
    const newCapacityButtonRef = useRef<HTMLDivElement>(null);

    // Determine if the create button should be disabled
    const isCreateButtonDisabled = !workspaceName || !termsConditions || !dataStorageLocation;

    return (
        <>
            <form>
                {isMessageBarVisible && <FormErrorMessageBar messageTitle={mode.toString()} />}
                <DialogContent className={classes.dialogContent}>
                    <WorkspaceName
                        value={workspaceName}
                        required={true}
                        preventDuplicate
                        onChange={(value) => setWorkspaceName(value)}
                    />
                    {!hideCapacitySelectField && (
                        <div ref={newCapacityButtonRef}>
                            <CapacitySelectField
                                value={capacity}
                                onOptionSelect={(capacity) => {
                                    setCapacity(capacity.name);
                                    console.log(capacity.name);
                                }}
                                required={true}
                                disabled={!hasPermission && mode === 'edit'}
                            />
                            <Link
                                className={classes.newCapacityLinkStyle}
                                onClick={() => {
                                    setIsCreateCapacityDialogOpen(true);
                                }}
                                disabled={!hasPermission && mode === 'edit'}
                            >
                                {tAdmin('Workspaces.Form.CreateNewCapacityLink')}
                            </Link>
                        </div>
                    )}
                    <StorageLocation
                        required={true}
                        value={dataStorageLocation}
                        onOptionSelect={(selectedLocation) =>
                            setDataStorageLocation(selectedLocation)
                        }
                    />
                    <ProductImprovement
                        value={productImprovement}
                        onChange={(checked) => setProductImprovement(checked)}
                    />
                    <ModelImprovement
                        value={modelImprovement}
                        onChange={(checked) => setModelImprovement(checked)}
                    />
                    <TermsConditions
                        value={termsConditions}
                        onChange={(checked) => setTermsConditions(checked)}
                        required
                    />
                </DialogContent>
                <DialogActions className={classes.actions}>
                    <div className={classes.rightActions}>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">{t('ButtonLabels.Cancel')}</Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            onClick={handleFormSubmit}
                            disabled={isCreateButtonDisabled}
                        >
                            {primaryButtonLabel}
                        </Button>
                    </div>
                </DialogActions>
            </form>
            <CreateCapacity
                isCreateCapacityDialogOpen={isCreateCapacityDialogOpen}
                onCreateCapacityDialogClose={() => {
                    setIsCreateCapacityDialogOpen(false);
                    //newCapacityButtonRef.current?.focus();
                }}
                hideWorkspaceSelectField={true}
                onCapacityCreated={handleCapacityCreated}
            />
            {overlay && (
                <WorkspacesLoadingOverlay
                    label={
                        mode === 'create'
                            ? tAdmin('ManageWorkspaces.CreatingWorkspace')
                            : tAdmin('ManageWorkspaces.DuplicatingWorkspace')
                    }
                />
            )}
        </>
    );
}

export default CreateEdit;
