import {useEffect, useMemo, useState} from 'react';
import useClasses from './NewSession.styles';
import {SkillDescriptor} from '@/api/skills';
import {useLayout} from '@/components/ui/Layout';
import {BackgroundAnimation, BackgroundImage} from '@/components/ui/Background';
import {PromptBar, PromptBarSubmitOptions} from '@/components/ui';
import {Caption1, Divider} from '@fluentui/react-components';
import {
    PromptbookInputs,
    PromptbookPrompts,
    SkillInputs,
    useGetPromptbook,
} from '@/api/promptbooks';
import {PromptCategory, PromptType} from '@/api/prompts';
import usePromptbarSubmit from '@/components/ui/PromptBar/usePromptbarSubmit';
import PromptbookFormNewSession from '@/components/ui/PromptBar/PromptbookFormNewSession';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {getCachedUrlParamFlags} from '@/util/hasStaticFeatureFlags';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import {MedeinaUrlParamFeatures} from '@/util/features';
import SkillsetCreator from '@/components/ui/SkillsetCreator/SkillsetCreator';

export default function NewSession(promptbookId: any) {
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const classes = useClasses();
    const {t} = useTranslation('session');
    const {background} = useLayout();
    let params = useParams();
    const [promptBookId, setPromptBookId] = useState<string>(params?.promptbookId!);
    const {data: promptbook} = useGetPromptbook({promptbookId: promptBookId!});
    const [promptbookInputs, setPromptbookInputs] = useState<PromptbookInputs>();
    const [skillInputs, setSkillInputs] = useState<{[key: string]: SkillInputs}>();
    const [promptbookPrompts, setPromptbookPrompts] = useState<PromptbookPrompts[] | null>(
        promptbook?.prompts ?? null,
    );

    // getting the feature flags and skillsets
    const userFeatureFlags = useMemo<string[] | undefined>(() => {
        const flags = getCachedUrlParamFlags(false);
        return flags.length > 0 ? flags : undefined;
    }, []);

    const isAgentViewEnabled = useFeatureFlag(MedeinaUrlParamFeatures.AgentView);

    const userSkillsets = useMemo<string[] | undefined>(() => {
        const params = new URLSearchParams(window.location.search);
        return (
            params
                .get('skillsets')
                ?.split(',')
                .map((item: string) => item.trim()) ?? undefined
        );
    }, [typeof window !== 'undefined' && window.location.search]);
    const {onSubmit: onDefaultSubmit, errorCreateSessionWithPromptbook} = usePromptbarSubmit();

    const comparePrompts = (prompt1: PromptbookPrompts, prompt2: PromptbookPrompts) => {
        const keysToCompare = [
            'promptType',
            'content',
            'skillName',
            'plugins',
            'skillInputDescriptors',
            'continueOnFailure',
        ];
        return keysToCompare.every((key) => (prompt1 as any)[key] === (prompt2 as any)[key]);
    };

    const checkForOverriddenPrompts = (
        originalPrompts: PromptbookPrompts[] | undefined,
        updatedPrompts: PromptbookPrompts[] | null,
    ) => {
        if (originalPrompts && updatedPrompts) {
            if (originalPrompts.length !== updatedPrompts.length) {
                return true;
            }
            return !originalPrompts.every((originalPrompt, index) => {
                const updatedPrompt = updatedPrompts[index];
                return comparePrompts(originalPrompt, updatedPrompt);
            });
        }
        return true;
    };

    const handleSubmit = () => {
        const overriddenPrompts = checkForOverriddenPrompts(promptbook?.prompts, promptbookPrompts);
        let options: PromptBarSubmitOptions | null = null;
        options = {
            promptType: PromptType.Prompt,
            inputs: promptbookInputs,
            content: promptbook?.name,
            promptbookId: promptbook?.promptbookId,
            promptbookPrompts: overriddenPrompts ? promptbookPrompts : null,
            skillsets: userSkillsets ?? undefined,
            featureFlags: userFeatureFlags ?? undefined,
            promptCategory: PromptCategory.UserGenerated,
            skillInputs: skillInputs,
        };

        onDefaultSubmit?.(options);
    };

    const handleOnPromptsChange = (prompts: PromptbookPrompts[]) => {
        // Handle creating SkillInputs here
        if (isDirectSkillInvocationEnabled) {
            const skillInputValues = prompts.reduce<{[key: string]: SkillInputs}>(
                (dict, prompt) => {
                    const key = prompt.promptbookPromptId;
                    if (
                        prompt.inputs !== undefined &&
                        prompt.promptType == PromptType.Skill &&
                        key
                    ) {
                        dict[key] = {InputParameters: prompt.inputs};
                    }
                    return dict;
                },
                {},
            );
            setSkillInputs(skillInputValues);
        }
        setPromptbookPrompts(prompts);
    };

    // Loading the promptbook prompts once the promptbook is loaded.
    useEffect(() => {
        setPromptbookPrompts(promptbook?.prompts ?? null);
    }, [promptbook]);

    useEffect(() => {
        background?.setImage?.(BackgroundImage.triangles);
        background?.setAnimation?.(BackgroundAnimation.bottomGradient);

        return () => {
            background?.setImage?.(BackgroundImage.none);
            background?.setAnimation?.(BackgroundAnimation.none);
        };
    }, [background]);
    const [defaultPromptSkill, setDefaultPromptSkill] = useState<SkillDescriptor | undefined>(
        undefined,
    );

    return (
        <div className={classes.page}>
            {isAgentViewEnabled && !Boolean(params?.promptbookId) ? (
                <div className={classes.agentRoot} data-testid="new-session-agents">
                    <div className={classes.skillsetEditor}>
                        <SkillsetCreator />
                    </div>
                    <PromptBar
                        defaultSkill={defaultPromptSkill}
                        enableSkillsetConnector
                        enableSupportAssistance
                    />
                    <Caption1 className={classes.aiCaption}>
                        {t('AIGeneratedContentWarning')}
                    </Caption1>
                </div>
            ) : (
                <div className={classes.root} data-testid="new-session-page">
                    {promptbook && Boolean(params?.promptbookId) ? (
                        <>
                            <Divider appearance="brand"></Divider>
                            <div className={classes.form}>
                                <div className={classes.skillFormWrapper}>
                                    <PromptbookFormNewSession
                                        promptbook={promptbook}
                                        promptbookInputs={promptbookInputs}
                                        promptbookPrompts={null}
                                        onChange={(inputs) => setPromptbookInputs(inputs)}
                                        onSubmit={handleSubmit}
                                        open={true}
                                        onPromptsChange={(prompts) =>
                                            handleOnPromptsChange(prompts)
                                        }
                                        errorCreateSessionWithPromptbook={
                                            errorCreateSessionWithPromptbook
                                        }
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        !Boolean(params?.promptbookId) && (
                            <>
                                <PromptBar
                                    defaultSkill={defaultPromptSkill}
                                    enableSkillsetConnector
                                    enableSupportAssistance
                                />
                                <Caption1 className={classes.aiCaption}>
                                    {t('AIGeneratedContentWarning')}
                                </Caption1>
                            </>
                        )
                    )}
                </div>
            )}
        </div>
    );
}
