import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Link,
    Menu,
    MenuItem,
    MenuItemLink,
    MenuList,
    MenuPopover,
    MenuTrigger,
    SpinButton,
    Spinner,
    Tooltip,
} from '@fluentui/react-components';
import useClasses from './ChangeCapacity.styles';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {ExternalLinkIcon, DismissIcon, MoreIcon} from '@/components/ui/icons';
import {OverageState, useGetCapacityByName, usePatchCapacity} from '@/api/capacities';
import useAzureCapacityDependencyLinkParts from '@/util/useAzureCapacityDependencyLinkParts';
import ToastNotification from '@/components/ui/Toasts/ToastNotification';
import {Intent} from '@/components/ui/Toasts/ToastNotification.types';
import {useWorkspaceState} from '@/components/workspaces/workspaceStateProvider';
import {getCurrentTenantId} from '@/util/msal/authConfig';
import {GEO_DATA} from '@/components/App.constants';
import {GeoName} from '@/components/App.types';
import {useTranslation} from 'react-i18next';
import {ARIA_LABELS, CONTROL_LABELS, STATIC_CONTENT} from './ChangeCapacity.constants';
import DeleteCapacityConfirmation from './DeleteCapacityConfirmation/DeleteCapacityConfirmation';
import UpdateCapacityConfirmation from './UpdateCapacityConfirmation/UpdateCapacityConfirmation';
import formatCurrency from '@/util/currencyFormatterUtil';
import MedeinaVariables from '@/util/variables';
import {AnnounceText} from '@/components/ui/AnnounceText';
import {MedeinaEvent, MedeinaTelemetryEvent, useTrackEvent} from '@/api/telemetry';
import OveragePanel from './OveragePanel';
import {OVERAGE_SCU_COST} from '../UsageDashboard/UsageDashboard.constants';

interface ChangeCapacityProps {
    isChangeCapacityDialogOpen: boolean;
    onChangeCapacityDialogClose: () => void;
    isOverageEnabled?: boolean;
}

export default function ChangeCapacity(props: ChangeCapacityProps) {
    const classes = useClasses();
    const {capacityId, capacityName} = useWorkspaceState();
    const {t: tCommon} = useTranslation('common');
    const {t: tAdmin} = useTranslation('admin');
    const {mutate: trackEvent} = useTrackEvent();

    const isOverageEnabled = props.isOverageEnabled || false;

    const {isChangeCapacityDialogOpen, onChangeCapacityDialogClose} = props;
    const [capacityValue, setCapacityValue] = useState<number>(0);
    const [overageUnitDetails, setOverageUnitDetails] = useState({
        useOverage: false,
        unlimitedOverage: false,
        limitValue: 0,
    });

    const [capacityToOverageDetails, setCapacityToOverageDetails] = useState({
        useOverage: false,
        unlimitedOverage: false,
        limitValue: 0,
    });

    const {data: capacity} = useGetCapacityByName(capacityName || '', {enabled: !!capacityName});
    const {
        mutate: updateCapacityUnits,
        isLoading: isUpdateCapacityLoading,
        isError: isUpdateCapacityError,
    } = usePatchCapacity();

    const [isDeleteCapacityOpen, setDeleteCapacityOpen] = useState<boolean>(false);
    const [isUpdateCapacitySuccessOpen, setIsUpdateCapacitySuccessOpen] = useState<boolean>(false);

    //This is for display purposes till the actual implementation is performed
    // const [errorMessage, setErrorMessage] = useState<string>(
    //     STATIC_CONTENT.TENANT_READ_ONLY_ACCESS_MESSAGE,
    // );

    // To fetch subscription and resourceGroup while making an ARM API call
    const tenantId = getCurrentTenantId();
    const {getSubscriptionId, getResourceGroupName, getBillingTabUrlOnSubscriptionPage} =
        useAzureCapacityDependencyLinkParts({capacityId: capacityId || '', tenantId});
    const {subscriptionId, resourceGroupName, manageBillingPageUrl} = useMemo(() => {
        return {
            subscriptionId: getSubscriptionId(),
            resourceGroupName: getResourceGroupName(),
            manageBillingPageUrl: getBillingTabUrlOnSubscriptionPage(),
        };
    }, [capacityId]);

    // To disable apply updated units value button
    const isCapacityValueUpdated = useMemo<boolean>(() => {
        if (capacity) {
            return capacityValue !== capacity?.properties?.numberOfUnits;
        }
        return false;
    }, [capacityValue, capacity]);

    const haveOverageDetailsChanged = useMemo<boolean>(() => {
        if (!props.isOverageEnabled) {
            return false;
        }

        if (capacity) {
            // compare between overagedetails & capacityToOverageDetails
            // if any of the values changed then return true
            if (overageUnitDetails.useOverage !== capacityToOverageDetails.useOverage) {
                return true;
            }
            if (overageUnitDetails.unlimitedOverage !== capacityToOverageDetails.unlimitedOverage) {
                return true;
            }
            if (overageUnitDetails.limitValue !== capacityToOverageDetails.limitValue) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }, [overageUnitDetails]);

    const {estimatedMonthlyCost, maxCapacityValue, minCapacityValue} = useMemo(() => {
        let maxCapacityValue = 0;
        let minCapacityValue = 0;
        let estimatedMonthlyCostValue = 0;

        if (capacity?.properties?.geo) {
            const geo = GEO_DATA[capacity.properties.geo.toUpperCase() as GeoName];
            estimatedMonthlyCostValue = geo.pricing * 24 * 30 * capacityValue;
            maxCapacityValue = geo.maxCapacity;
            minCapacityValue = geo.minCapacity;
        }

        const estimatedMonthlyCost = formatCurrency(estimatedMonthlyCostValue, 'USD', 'en-US');

        return {estimatedMonthlyCost, maxCapacityValue, minCapacityValue};
    }, [capacityValue, capacity]);

    useEffect(() => {
        if (isOverageEnabled && capacity && Boolean(capacity.properties)) {
            setOverageUnitDetails({
                useOverage:
                    Boolean(capacity.properties.overageState) &&
                    capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: capacity.properties.overageState === OverageState.Unlimited,
                limitValue: capacity.properties.overageAmount || 0,
            });
            setCapacityToOverageDetails({
                useOverage:
                    Boolean(capacity.properties.overageState) &&
                    capacity.properties.overageState !== OverageState.None,
                unlimitedOverage: capacity.properties.overageState === OverageState.Unlimited,
                limitValue: capacity.properties.overageAmount || 0,
            });
        }
    }, [capacity]);

    // Actions to take on close/dismiss of change capacity dialog
    const closeCapacityDialog = () => {
        onChangeCapacityDialogClose();
        setDeleteCapacityOpen(false);
        setIsUpdateCapacitySuccessOpen(false);
    };

    const setSpinValue = useCallback(
        (event: any, data: any) => {
            if (data.value !== undefined) {
                setCapacityValue(data.value);
            } else if (data.displayValue !== undefined) {
                const newValue = parseFloat(data.displayValue);
                if (
                    !Number.isNaN(newValue) &&
                    newValue >= minCapacityValue &&
                    newValue <= maxCapacityValue
                ) {
                    setCapacityValue(newValue);
                }
            }
        },
        [setCapacityValue],
    );

    const openUpdateCapacity = () => {
        if (capacityName) {
            let updateCapacityRequest = {
                subscriptionId,
                resourceGroupName,
                capacityName,
                properties: {
                    numberOfUnits: capacityValue,
                },
            };

            if (isOverageEnabled) {
                let overageParams = {
                    overageState: overageUnitDetails.unlimitedOverage
                        ? OverageState.Unlimited
                        : OverageState.Limited,
                    overageAmount: overageUnitDetails.limitValue,
                };

                updateCapacityRequest = {
                    ...updateCapacityRequest,
                    properties: {
                        ...updateCapacityRequest.properties,
                        ...overageParams,
                    },
                };
            }
            updateCapacityUnits(updateCapacityRequest, {
                onSuccess: () => {
                    setIsUpdateCapacitySuccessOpen(true);
                },
                onSettled: () => {
                    onChangeCapacityDialogClose();
                },
            });
        }
    };

    //To set capacityValue once the capacity has been fetched from Fidelis API
    useEffect(() => {
        setCapacityValue(capacity?.properties?.numberOfUnits || 0);
    }, [capacity]);

    // To reset change capacity dialog on opening with capacity value from API
    useEffect(() => {
        if (isChangeCapacityDialogOpen) {
            setCapacityValue(capacity?.properties?.numberOfUnits || 0);
        }
    }, [isChangeCapacityDialogOpen]);

    // onOpenChange is triggered only on dialog close as the opening is controlled by parent prop, not within <Dialog />.
    return (
        <div data-testid="change-capacity-dialog">
            <Dialog
                open={isChangeCapacityDialogOpen && !isDeleteCapacityOpen}
                onOpenChange={closeCapacityDialog}
            >
                <DialogSurface>
                    <DialogBody data-testid="change-capacity-dialog-body">
                        <DialogTitle className={classes.dialogTitleSection}>
                            {tAdmin(STATIC_CONTENT.SECURITY_COMPUTE_UNITS)}
                            <DialogTrigger disableButtonEnhancement>
                                <Button
                                    data-testid="close-change-capacity-dialog-button"
                                    appearance="transparent"
                                    aria-label={tAdmin(ARIA_LABELS.CLOSE_CHANGE_CAPACITY_DIALOG)}
                                    icon={<DismissIcon />}
                                />
                            </DialogTrigger>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContentSection}>
                            <div
                                className={classes.contentSection}
                                data-testid="dialog-content-section"
                            >
                                {tAdmin(STATIC_CONTENT.CHANGE_CAPACITY_INFORMATION, {capacityName})}{' '}
                                <Link
                                    data-test-id="read-more-about-security-compute-units-link"
                                    target="_blank"
                                    href={MedeinaVariables.SecurityComputeUnitsUri}
                                >
                                    {tAdmin(STATIC_CONTENT.READ_MORE_COMPUTE_UNITS)}
                                </Link>
                            </div>
                            <div className={classes.numericSection}>
                                <div className={classes.spinButtonSection}>
                                    <SpinButton
                                        data-test-id="capacity-units-spin-button"
                                        min={minCapacityValue}
                                        max={maxCapacityValue}
                                        value={capacityValue}
                                        onChange={setSpinValue}
                                        className={classes.spinButton}
                                        aria-label={tAdmin(ARIA_LABELS.CHANGE_CAPACITY_UNITS)}
                                    />
                                </div>
                                <div className={classes.estimatedMonthlyCostSection}>
                                    <AnnounceText textProps={{as: 'span'}} ariaLive="polite">
                                        <div>{tAdmin(STATIC_CONTENT.ESTIMATED_MONTHLY_COST)}</div>
                                        <div>
                                            <span className={classes.costPerMonth}>{`${tAdmin(
                                                STATIC_CONTENT.USD,
                                            )} ${estimatedMonthlyCost}`}</span>
                                            {`${tAdmin(STATIC_CONTENT.MONTH)}`}
                                        </div>
                                    </AnnounceText>
                                </div>
                            </div>
                            {isOverageEnabled && (
                                <div className={classes.changeCapacityOverageSection}>
                                    <OveragePanel
                                        needsOverageUnits={overageUnitDetails.useOverage}
                                        unlimitedOverage={overageUnitDetails.unlimitedOverage}
                                        onOverageChange={(useOverage) => {
                                            setOverageUnitDetails({
                                                ...overageUnitDetails,
                                                ...useOverage,
                                            });
                                        }}
                                        limitValue={overageUnitDetails.limitValue}
                                        scuCost={OVERAGE_SCU_COST}
                                    />
                                </div>
                            )}
                        </DialogContent>
                        <DialogActions className={classes.dialogActions}>
                            <Button
                                data-test-id="update-capacity-units-button"
                                appearance="primary"
                                onClick={() => {
                                    openUpdateCapacity();
                                    trackEvent({
                                        name: MedeinaTelemetryEvent.Capacity.UpdateCapacity,
                                        eventType: MedeinaEvent.ActionEvent,
                                    });
                                }}
                                disabled={
                                    !(isCapacityValueUpdated || haveOverageDetailsChanged) ||
                                    isUpdateCapacityLoading
                                }
                            >
                                {!isUpdateCapacityLoading ? (
                                    tCommon(CONTROL_LABELS.APPLY)
                                ) : (
                                    <Spinner size="tiny" />
                                )}
                            </Button>
                            <DialogTrigger disableButtonEnhancement>
                                <Button data-test-id="close-capacity-button" appearance="secondary">
                                    {tCommon(CONTROL_LABELS.CANCEL)}
                                </Button>
                            </DialogTrigger>
                            <Menu>
                                <MenuTrigger disableButtonEnhancement>
                                    <Tooltip
                                        content={tCommon('ButtonLabels.MoreOptions')}
                                        relationship="label"
                                    >
                                        <Button
                                            data-test-id="more-options-button"
                                            icon={<MoreIcon />}
                                        />
                                    </Tooltip>
                                </MenuTrigger>
                                <MenuPopover>
                                    <MenuList>
                                        <MenuItemLink
                                            data-test-id="manage-billing-in-azure-link"
                                            href={manageBillingPageUrl}
                                            target="_blank"
                                            role="menuitem"
                                            secondaryContent={
                                                <ExternalLinkIcon aria-label="External link" />
                                            }
                                        >
                                            {tAdmin(STATIC_CONTENT.MANAGE_BILLING_AZURE)}
                                        </MenuItemLink>
                                        <MenuItem
                                            data-test-id="detete-capacity-menu-item"
                                            onClick={() => {
                                                setDeleteCapacityOpen(true);
                                                trackEvent({
                                                    name: MedeinaTelemetryEvent.Capacity
                                                        .DeleteCapacity,
                                                    eventType: MedeinaEvent.ActionEvent,
                                                });
                                            }}
                                            className={classes.deleteCapacityMenuItem}
                                            disabled={!capacity}
                                        >
                                            {tAdmin(CONTROL_LABELS.DELETE_CAPACITY)}
                                        </MenuItem>
                                    </MenuList>
                                </MenuPopover>
                            </Menu>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            <DeleteCapacityConfirmation
                isDeleteCapacityConfirmDialogOpen={isDeleteCapacityOpen}
                onDeleteCapacityConfirmDialogClose={closeCapacityDialog}
            />
            <UpdateCapacityConfirmation
                isUpdateCapacityConfirmDialogOpen={isUpdateCapacitySuccessOpen}
                onUpdateCapacityConfirmDialogClose={closeCapacityDialog}
                capacityValueToUpdate={capacityValue}
            />
            {isUpdateCapacityError && (
                <ToastNotification
                    intent={Intent.Error}
                    message={tAdmin(STATIC_CONTENT.UPDATE_CAPACITY_ERROR)}
                    sessionId={''}
                />
            )}
        </div>
    );
}
