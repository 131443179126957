import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
    OverageState,
    PatchCapacityRequest,
    PatchCapacityResponse,
} from '../capacities/capacities.types';
import useFetch from '../useFetch';
import {RESOURCE_SCOPES} from '../api.constants';
import MedeinaVariables from '@/util/variables';
import MedeinaFeatures from '@/util/features';
import {useFeatureFlag} from '../user';

export default function usePatchCapacity() {
    const queryClient = useQueryClient();
    const {customFetch} = useFetch();
    let enableUsageDashboardOverage = useFeatureFlag(MedeinaFeatures.EnableUsageDashboardOverage);

    return useMutation({
        mutationFn: async (request: PatchCapacityRequest) => {
            if (!Boolean(request.properties?.overageState)) {
                request.properties.overageState = OverageState.None;
            }

            let apiVersion = enableUsageDashboardOverage
                ? '2024-11-01-preview'
                : '2023-12-01-preview';

            // This check is in place to ensure that the overage state and amount are not sent to the backend if the feature is disabled
            if (!enableUsageDashboardOverage && Boolean(request.properties)) {
                delete request.properties.overageState;
                delete request.properties.overageAmount;
            }

            return await customFetch<PatchCapacityResponse>(
                `${MedeinaVariables.ArmUri}/subscriptions/${request.subscriptionId}/resourceGroups/${request.resourceGroupName}/providers/Microsoft.SecurityCopilot/capacities/${request.capacityName}?api-version=${apiVersion}`,
                {
                    method: 'PATCH',
                    scopes: RESOURCE_SCOPES.ARM,
                    body: {
                        properties: request.properties,
                    },
                },
            );
        },
        onSuccess: (_, variables) => {
            // Invalidate the cache
            queryClient.invalidateQueries({queryKey: ['capacities', variables.capacityName]});
        },
    });
}
