import {IVerticalStackedChartProps} from '@fluentui/react-charting';
import useClasses from './BarChartHoverCard.styles';
import {useMemo} from 'react';
import {CAPACITY_BUCKETS} from '../../UsageDashboard.constants';
import {useTranslation} from 'react-i18next';
import HoverCardUsage from './HoverCardUsage';
import overageChartConfigurator from '../ChartConfigurators/overageChartConfigurator';
import {chartDataExtractor} from '../BarChartHoverCard.utils';

/**
 * Props for the OverageHoverCard component.
 *
 * @interface OverageHoverCardProps
 * @property {IVerticalStackedChartProps} calloutData - Data to be displayed in the hover card.
 */
interface OverageHoverCardProps {
    calloutData: IVerticalStackedChartProps;
}

export default function OverageHoverCard(props: OverageHoverCardProps) {
    const classes = useClasses();
    const {t} = useTranslation('admin');

    const {getChartDataItem} = chartDataExtractor(props.calloutData);

    const configurator = new overageChartConfigurator(t);

    const matchingStatus = useMemo(() => {
        return Object.entries(configurator.getMapForOnHoverMatching()).find(
            ([key]) => key === props.calloutData.xAxisCalloutData,
        );
    }, [props.calloutData.xAxisCalloutData]);

    const overageAmount = getChartDataItem(CAPACITY_BUCKETS.WITHIN_OVERAGE_LIMITS)?.data;
    const belowOverageAmount = getChartDataItem(CAPACITY_BUCKETS.WITHIN_PROVISIONED_LIMITS)?.data;

    const usageStatus = props.calloutData.xAxisCalloutData && matchingStatus;

    return (
        <>
            <div className={classes.usageHoverCardRoot} data-testid="overage-hover-card">
                {usageStatus && (
                    <div className={classes.usageStatusRoot} data-testid="usage-status">
                        <div className={classes.usageStatus}>
                            <div className={classes.usageStatusIcon}>{matchingStatus[1].icon}</div>
                            <div className={classes.usageStatusText}>
                                {t(matchingStatus[1].status)}
                            </div>
                        </div>
                        <div>
                            {matchingStatus[1].supplementary && (
                                <div>{t(matchingStatus[1].supplementary)}</div>
                            )}
                        </div>
                    </div>
                )}
                <>
                    {parseInt(String(overageAmount ?? 0)) > 0 && (
                        <HoverCardUsage
                            usageValue={String(overageAmount)}
                            usageType={'aboveOverage'}
                        ></HoverCardUsage>
                    )}
                    <HoverCardUsage
                        usageValue={String(belowOverageAmount)}
                        usageType={'provisioned'}
                    ></HoverCardUsage>
                </>
            </div>
        </>
    );
}
