import {HourlyCapacityUsage, OverageState} from '@/api/capacities';
import {
    ILineDataInVerticalStackedBarChart,
    IVerticalStackedChartProps,
    IVSChartDataPoint,
} from '@fluentui/react-charting';
import {IUsageChartConfigurator, OverageChartUsageData} from '../VStackedBarChart.types';
import {
    getDayName,
    getHourlyTimeframe,
    getZonedDate,
    roundToDecimalPlaces,
} from '../../UsageDashboard.utils';
import {
    CAPACITY_BUCKETS,
    CAPACITY_LIMIT_TYPES,
    UNIT_PLURAL_TEXT,
    USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
} from '../../UsageDashboard.constants';
import {tokens} from '@fluentui/react-components';
import {WarningIcon} from '@/components/ui/icons';
import {
    appendIntermediateSpikeElements,
    detectUnlimitedOverageTraversal,
} from './overageChartConfigurator.utils';

export default class overageChartConfigurator implements IUsageChartConfigurator {
    private translator!: Function;

    private OVERAGE_LINE_COLOR: string = tokens.colorBrandForeground2;
    private ASSIGNED_CAPACITY_LINE_COLOR: string = tokens.colorStrokeFocus2;
    private HIDDEN_OVERAGE_LINE_COLOR: string = 'transparent';

    constructor(contentTranslator: Function) {
        this.translator = contentTranslator;
    }

    regenerateData(data: HourlyCapacityUsage[]): HourlyCapacityUsage[] {
        let updatedResult = appendIntermediateSpikeElements(data);

        updatedResult = detectUnlimitedOverageTraversal(updatedResult);

        return updatedResult;
    }

    getMapForOnHoverMatching = (): {
        [key: string]: {
            icon: JSX.Element;
            status: string;
            supplementary?: string;
            ariaLabel: string;
        };
    } => {
        return {
            [`${CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS}`]: {
                icon: <></>,
                status: '',
                ariaLabel: `${CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS}.UnitStatusAriaLabel`,
            },
            [`${CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS}`]: {
                icon: <WarningIcon color="#FFBA66" data-testid="warning-above-overage" />,
                status: `${CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS}.UnitStatusText`,
                ariaLabel: `${CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS}.UnitStatusAriaLabel`,
            },
            [`${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}`]: {
                icon: <WarningIcon filled color="#C50F1F" data-testid="warning-throttled" />,
                status: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.UnitStatusText`,
                supplementary: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.Supplementary`,
                ariaLabel: `${CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE}.UnitStatusAriaLabel`,
            },
        };
    };

    getChartConfiguration = (
        hourlyUsageMetrics: HourlyCapacityUsage,
    ): IVerticalStackedChartProps => {
        const usageData = this.getUsageData(hourlyUsageMetrics);
        return this.createGraphItem(usageData);
    };

    private getUsageData = (item: HourlyCapacityUsage): OverageChartUsageData => {
        //Determining usage within the provisioning limits

        const provisionedUsage = roundToDecimalPlaces(
            item.provisionedSCUUsed ?? 0,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        const overageUsage = roundToDecimalPlaces(
            item.overageSCUUsed ?? 0,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        const unbilledUsage = roundToDecimalPlaces(
            item.unbilledSCUUsed ?? 0,
            USAGE_DATA_TRUNCATION_DECIMAL_COUNT,
        );

        const overageState = item.overageState ?? OverageState.None;
        let usageBucket: string | null = null;

        if (overageState == OverageState.Limited) {
            if (item.unbilledSCUUsed ?? 0 > 0) {
                usageBucket = CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE;
            } else if (item.overageSCUUsed ?? 0 > 0) {
                usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS;
            } else {
                usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS;
            }
        } else if (overageState == OverageState.Unlimited) {
            if (item.overageSCUUsed ?? 0 > 0) {
                usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_OVERAGE_LIMITS;
            } else {
                usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS;
            }
        } else if (overageState == OverageState.None) {
            if (item.unbilledSCUUsed ?? 0 > 0) {
                usageBucket = CAPACITY_LIMIT_TYPES.EXCEEDED_OVERAGE;
            } else {
                usageBucket = CAPACITY_LIMIT_TYPES.WITHIN_PROVISIONED_LIMITS;
            }
        }

        return {
            provisionedUsage,
            overageUsage,
            unbilledUsage,
            usageBucket: String(usageBucket),
            overageState,
            assignedCapacity: item.assignedCapacity,
            usedCapacity: item.usedCapacity,
            overageLimit: item.overageLimit,
            aggregateStartTime: item.aggregateStartTime,
            isOverageConnectorHidden: item.doesUsageTraverseUnlimitedOverage ?? false,
        };
    };

    private createGraphItem = (usageData: OverageChartUsageData): IVerticalStackedChartProps => {
        // Convert input UTC date to user timezone date
        const zonedDate = new Date(getZonedDate(usageData.aggregateStartTime));
        const stackAccessibilityData = this.getStackAccessibilityData(
            usageData,
            zonedDate,
            usageData.assignedCapacity,
        );

        return {
            chartData: this.buildChartData(usageData),
            xAxisPoint: zonedDate, // BarChart doesn't respect string for custom formatting
            xAxisCalloutData: usageData.usageBucket, // Using as a placeholder to show usage status on custom callout
            lineData: this.buildLineData(usageData),
            // Override is required as xAxisCalloutData, used as a placeholder for the custom hover card component, also gets read.
            // The usageBucket, being a key for translation text rather than suitable display text, needs to be replaced.
            stackCallOutAccessibilityData: {
                ariaLabel: stackAccessibilityData,
            },
        };
    };

    private buildLineData = (
        usageData: OverageChartUsageData,
    ): ILineDataInVerticalStackedBarChart[] => {
        let chartDataPoints: ILineDataInVerticalStackedBarChart[] = [];

        // Prepare line for the Overage Limit
        let overageLimitConnector: ILineDataInVerticalStackedBarChart | null = null;

        if (usageData.overageState == OverageState.None) {
            overageLimitConnector = {
                y: usageData.assignedCapacity,
                legend: CAPACITY_BUCKETS.OVERAGE_LIMIT,
                color: this.OVERAGE_LINE_COLOR,
            };
        } else {
            overageLimitConnector = {
                y: usageData.assignedCapacity + usageData.overageLimit,
                legend: CAPACITY_BUCKETS.OVERAGE_LIMIT,
                color: this.OVERAGE_LINE_COLOR,
            };
        }

        chartDataPoints.push({
            ...overageLimitConnector,
            color: usageData.isOverageConnectorHidden
                ? this.HIDDEN_OVERAGE_LINE_COLOR
                : this.OVERAGE_LINE_COLOR,
        });

        // Prepare line for the Assigned Capacity
        chartDataPoints.push({
            y: usageData.assignedCapacity,
            legend: CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
            color: this.ASSIGNED_CAPACITY_LINE_COLOR,
        });

        return chartDataPoints;
    };

    private buildChartData = (usageData: OverageChartUsageData): IVSChartDataPoint[] => {
        const chartData: IVSChartDataPoint[] = [];
        const {overageState, provisionedUsage, overageUsage, unbilledUsage} = usageData;

        chartData.push({
            legend: CAPACITY_BUCKETS.WITHIN_PROVISIONED_LIMITS,
            data: provisionedUsage,
            color: tokens.colorBrandForegroundLink,
        });

        if (overageState != OverageState.None) {
            chartData.push({
                legend: CAPACITY_BUCKETS.WITHIN_OVERAGE_LIMITS,
                data: overageUsage,
                color: tokens.colorCompoundBrandStrokePressed,
            });
        }

        if (overageState != OverageState.Unlimited) {
            chartData.push({
                legend: CAPACITY_BUCKETS.EXCEEDED_OVERAGE,
                data: unbilledUsage,
                color: tokens.colorStatusDangerBackground3,
            });
        }

        return chartData;
    };

    private getStackAccessibilityData = (
        usageData: ReturnType<typeof this.getUsageData>,
        date: Date,
        assignedCapacity: number,
    ): string => {
        const {provisionedUsage, overageUsage, usageBucket, unbilledUsage} = usageData;

        const matchingStatus = Object.entries(this.getMapForOnHoverMatching()).find(
            ([key]) => key === usageBucket,
        );

        const usageBucketText = `${this.translator(matchingStatus?.[1].ariaLabel ?? '')}`;

        const overageUsageText = `${this.translator(
            CAPACITY_BUCKETS.WITHIN_OVERAGE_LIMITS,
        )} ${overageUsage} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const withinProvisioningLimits = `${this.translator(
            CAPACITY_BUCKETS.WITHIN_PROVISIONED_LIMITS,
        )} ${provisionedUsage} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const unbilledOverageText = `${this.translator(CAPACITY_BUCKETS.EXCEEDED_OVERAGE)} ${
            usageData.unbilledUsage
        } ${this.translator(UNIT_PLURAL_TEXT)}`;

        const assignedCapacityText = `${this.translator(
            CAPACITY_BUCKETS.ASSIGNED_CAPACITY,
        )} ${assignedCapacity} ${this.translator(UNIT_PLURAL_TEXT)}`;

        const dayName = getDayName(date);
        const timeframe = getHourlyTimeframe(date);

        return `${usageBucketText}. ${dayName} from ${timeframe} . ${withinProvisioningLimits}. ${overageUsageText}. ${overageUsage}. ${unbilledOverageText}. ${unbilledUsage}. ${assignedCapacityText}`;
    };
}
