import React, {useCallback} from 'react';
import {useGetAccount} from '@/api/accounts';
import {PutWorkspaceRequest, CreateGeoWorkspaceRequest, Workspace} from './workspaces.types';
import {getAccountGeoCodes} from './util';
import usePutWorkspace from './usePutWorkspace';
import useEnableGeo from '@/api/accounts/useEnableGeo';

/** A hook that creates a new Microsoft Security Graph Workspace
 * It checks if the targeted workspace is in a geo that has been enabled for the account
 * if it is not it will enable the geo before creating the workspace
 * If it is already enabled it will create the workspace in the targeted geo
 *
 * If the workspace is created successfully it will return the workspace object
 * If the workspace is not created successfully (geo enablement fails, or workspace creation fails) it will return an error object
 */
const managementUrlBase = '.api.securityplatform.microsoft.com';
function useCreateWorkspace() {
    // Get the account for the tenant
    const {
        data: account,
        error: accountError,
        isLoading: accountIsLoading,
        isSuccess: accountIsSuccess,
    } = useGetAccount({
        onError: (error) => {
            console.error('Failed to get account', error);
            throw error;
        },
    });
    const {refetch: refetchAccount} = useGetAccount();

    // Setup the mutation calls
    // Creates workspace object
    const putWorkspace = usePutWorkspace();
    const enableGeo = useEnableGeo();

    const accountGeoCodes = accountIsSuccess && account.pods && getAccountGeoCodes(account.pods);

    const needsGeoEnablement = useCallback(
        (targetWorkspace: PutWorkspaceRequest) =>
            targetWorkspace.dataStorageLocation &&
            accountGeoCodes &&
            !accountGeoCodes.includes(targetWorkspace.dataStorageLocation),
        [accountGeoCodes],
    );
    // console.log({account, accountError, accountIsLoading, accountGeoCodes, needsGeoEnablement});

    const createWorkspace = useCallback(
        (targetWorkspace: PutWorkspaceRequest): Promise<Workspace> => {
            return new Promise((resolve, reject) => {
                // handle the creation of the workspace, either before or after enabling the geo
                putWorkspace.mutate(
                    {
                        request: targetWorkspace,
                        targetWorkspace: targetWorkspace,
                    },
                    {
                        onError: (error) => {
                            console.error('Failed to create workspace', error);
                            reject(error);
                        },
                        onSuccess: (workspace: Workspace) => {
                            console.log('Workspace created successfully', workspace);
                            resolve(workspace);
                        },
                    },
                );
            });
        },
        [putWorkspace],
    );

    const handleCreate = useCallback(
        async (
            targetWorkspace: CreateGeoWorkspaceRequest,
        ): Promise<CreateGeoWorkspaceRequest | void> => {
            console.log('Starting create workspace');
            console.log('Incoming target workspace', targetWorkspace);
            const needsGeo = needsGeoEnablement(targetWorkspace);
            if (needsGeo === undefined) {
                // there's an error with either our account callback or the needsGeoEnablement function
            } else if (needsGeo) {
                try {
                    // Enable the geo
                    enableGeo.mutate(
                        {geoName: targetWorkspace.dataStorageLocation || ''},
                        {
                            onError: (error) => {
                                console.error('Failed to enable geo', error);
                                throw error;
                            },
                            onSuccess: async () => {
                                // create the workspace
                                // change to use data in parentheses
                                const result = await refetchAccount();
                                const geoPod = result?.data?.pods?.find(
                                    (pod) => pod.geo === targetWorkspace.dataStorageLocation,
                                );
                                if (!geoPod) {
                                    console.error('Geo is not enabled in the account');
                                }
                                // flesh out targetWorkspace data
                                targetWorkspace.path =
                                    'pods/' + geoPod?.podId + '/workspaces/' + targetWorkspace.name;
                                targetWorkspace.managementUrl = geoPod?.geoCode + managementUrlBase;
                                if (targetWorkspace.capacity?.referenceName == '') {
                                    delete targetWorkspace.capacity;
                                }
                                createWorkspace(targetWorkspace);
                                return targetWorkspace;
                            },
                        },
                    );
                } catch (e) {
                    console.log('Failed to create workspace, did not catch in hook');
                    throw e;
                }
            } else {
                // The geo is already in the account and does not need enabling
                // call the createWorkspace hook directly
                const geoPod = account?.pods?.find(
                    (pod) => pod.geo === targetWorkspace.dataStorageLocation,
                );
                targetWorkspace.path =
                    'pods/' + geoPod?.podId + '/workspaces/' + targetWorkspace.name;
                targetWorkspace.managementUrl = geoPod?.geoCode + managementUrlBase;
                if (targetWorkspace.capacity?.referenceName == '') {
                    delete targetWorkspace.capacity;
                }
                await createWorkspace(targetWorkspace);
                return targetWorkspace;
            }
        },
        [account?.pods, createWorkspace, enableGeo, needsGeoEnablement, refetchAccount],
    );
    return handleCreate;
}

export default useCreateWorkspace;
