import {makeStyles, tokens} from '@fluentui/react-components';

export default makeStyles({
    contentSection: {
        fontSize: tokens.fontSizeBase300,
        lineHeight: tokens.spacingVerticalXL,
        fontWeight: tokens.fontSizeBase400,
        textAlign: 'justify',
        marginBottom: '36px',
    },
    numericSection: {
        display: 'flex',
        flexDirection: 'row',

        '& > div:not(:last-child)': {
            marginRight: tokens.spacingHorizontalL,
        },
    },
    costPerMonth: {
        fontSize: tokens.fontSizeBase600,
        lineHeight: tokens.spacingVerticalXXXL,
        fontWeight: tokens.fontSizeBase600,
    },
    dialogTitleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    dialogContentSection: {
        marginBottom: '36px',
        marginTop: tokens.spacingVerticalS,
    },
    spinButtonSection: {
        width: '180px !important',

        '& > div:last-child': {
            color: tokens.colorPaletteCranberryBorderActive,

            '& > span:first-child': {
                position: 'relative',
                top: '3px',
            },
        },
    },
    spinButton: {
        height: '60px',
        fontSize: tokens.fontSizeHero800,

        '& .fui-SpinButton__incrementButton': {
            marginTop: tokens.spacingVerticalMNudge,
        },
    },
    estimatedMonthlyCostSection: {
        '& > div:first-child': {
            alignItems: 'center',
        },
    },
    menuItemIconContainer: {
        alignContent: 'center',
    },
    deleteCapacityMenuItem: {
        color: tokens.colorStatusDangerForeground1,
    },
    dialogActions: {
        flexDirection: 'row',
        '@media (max-width: 480px)': {
            flexDirection: 'row',
        },
    },
    overageButtonWrapper: {
        height: tokens.spacingVerticalXXXL,
        '> button': {
            marginRight: tokens.spacingHorizontalS,
        },
    },
    unitsDisplayWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    hiddenUnitsDisplayWrapper: {
        visibility: 'hidden',
    },
    changeCapacityOverageSection: {
        marginTop: '36px',
    },
    overagePanelbackground: {
        backgroundColor: tokens.colorNeutralBackground2,
        padding: tokens.spacingHorizontalL,
    },
    overageSwitchSection: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: tokens.spacingVerticalXXL,
    },
    overageSwitchContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    overageContentSection: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: tokens.spacingVerticalXXL,

        '> div:first-child': {
            marginBottom: tokens.spacingVerticalXS,
        },
    },
    unitsInput: {
        paddingLeft: '25px',
        width: '58px',
    },
});
