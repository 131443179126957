import {
    FormErrors,
    PromptbookCloseResult,
    PromptbookFormProps,
    PromptbookOperationMode,
} from './Promptbook.types';
import {escapeRegExp} from 'lodash-es';
import useClasses from './PromptbookCreateForm.styles';
import {
    Dropdown,
    Option,
    Field,
    Input,
    Textarea,
    Button,
    Dialog,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogTrigger,
    mergeClasses,
    TextareaOnChangeData,
    Spinner,
    MessageBar,
    MessageBarBody,
} from '@fluentui/react-components';
import {ChangeEvent, useEffect, useMemo, useRef, useState} from 'react';
import useGetPromptsFromPromptIds from '@/api/prompts/useGetPromptsFromPromptIds';
import {DismissIcon} from '@/components/ui/icons';
import {Prompt, PromptType} from '@/api/prompts';
import {
    CreatePromptbookRequest,
    PromptbookVisibility,
    PromptbookInputDescriptor,
    PromptbookPrompts,
    PromptbookDescriptor,
    PromptbookTag,
    EditPromptbookRequest,
} from '@/api/promptbooks/promptbooks.types';
import useCreatePromptbook from '@/api/promptbooks/useCreatePromptbook';
import PromptbookInputsForm from './PromptbookInputsForm';
import useCreatePromptbookFormValidations from './useCreatePromptbookFormValidations';
import {useTranslation} from 'react-i18next';
import useScrollStyles from '@/components/ui/util/MedeinaScrollbar.styles';
import PromptbookPromptList from './PromptbookPromptList';
import {difference, isEmpty} from 'lodash-es';
import PromptbookTags from './PromptbookTags';
import {ApiError} from '@/api/api';
import {useEditPromptbook} from '@/api/promptbooks';
import {SkillsetCategory, useGetSkillsets} from '@/api/skills';
import {GetEvaluationSkillInvocationsRequest} from '@/api/evaluations/evaluations.types';
import PromptbookPlugins, {PromptbookPlugin} from './PromptbookPlugins';
import useGetEvaluationsFromEvaluationIds from '@/api/evaluations/useGetEvaluationsFromEvaluationIds';
import {Evaluation, useGetEvaluationSkillInvocations} from '@/api/evaluations';
import {useFeatureFlag} from '@/api/user';
import MedeinaFeatures from '@/util/features';
import useGetWorkspaceSettings from '@/api/workspaces/useGetWorkspaceSettings';

const PRIVATE_SCOPE = PromptbookVisibility.Private.toString();
const TENANT_SCOPE = PromptbookVisibility.Tenant.toString();

const PromptbookCreateForm = ({
    promptbook,
    sessionId,
    promptIds,
    mode,
    onClose,
    open,
    onSuccess = () => null,
}: PromptbookFormProps) => {
    const classes = useClasses();
    const {t} = useTranslation('promptbooks');
    const {t: tCommon} = useTranslation('common');
    const isPromptbookAutoParametrizationEnabled = useFeatureFlag(
        MedeinaFeatures.EnablePromptbookAutoParametrization,
    );
    const isDirectSkillInvocationEnabled = useFeatureFlag(
        MedeinaFeatures.EnableDirectSkillsInPromptbook,
    );
    const scrollClasses = useScrollStyles();
    const createButtonRef = useRef<HTMLButtonElement>(null);
    const getPromptsResult = useGetPromptsFromPromptIds({sessionId, promptIds});
    // Fetch the list of skillsets which contains information like display name, icon for plugins.
    const {data: skillsets, isLoading: skillsetsLoading} = useGetSkillsets();
    // multi-workspaces
    const isWorkspacesTestingEnabled = useFeatureFlag(MedeinaFeatures.MultiWorkspaceEnabled);
    const {data: workspace} = useGetWorkspaceSettings(undefined, {
        enabled: Boolean(isWorkspacesTestingEnabled),
    });
    const visibilitySelectionStrings = {
        [PRIVATE_SCOPE]: t('ScopeJustMe'),
        [TENANT_SCOPE]: isWorkspacesTestingEnabled ? t('ScopeWorkspace') : t('ScopeTenant'),
    };

    // Eg: { value: 'Private', text: 'Just me' }
    const visibilityDropdownOptions = Object.keys(visibilitySelectionStrings).map((scope) => ({
        value: scope,
        text: visibilitySelectionStrings[scope],
    }));

    // Default the request to empty values. This will be set later based on the prompts and evaluations data.
    const [getEvaluationSkillInvocationRequest, setGetEvaluationSkillInvocationRequest] =
        useState<GetEvaluationSkillInvocationsRequest>({
            sessionId: '',
            promptIds: [],
            evaluationIds: [],
            enabled: false,
        });

    const getEvaluationSkillInvocationsResult = useGetEvaluationSkillInvocations(
        getEvaluationSkillInvocationRequest,
    );

    // Set the list of plugins from all prompts in createPromptbookRequest to be rendered.
    const [plugins, setPlugins] = useState<PromptbookPlugin[]>([]);

    // Plugins initialization from evaluation data is only needed in Create mode. In Duplicate, Edit modes,
    // the plugins are already in the promptbook prompts. We track the initialization using this state variable.
    const [pluginsInitialized, setpluginsInitialized] = useState<boolean>(
        mode === PromptbookOperationMode.Duplicate || mode === PromptbookOperationMode.Edit
            ? true
            : false,
    );

    const [selectedVisibilityScope, setSelectedVisibilityScope] = useState<string>(PRIVATE_SCOPE);

    const [promptEvaluationIds, setPromptEvaluationIds] = useState<[string, string][]>([]);

    const getEvaluationsResult = useGetEvaluationsFromEvaluationIds({
        sessionId,
        promptEvaluationIds,
    });

    const [createPromptbookRequest, setCreatePromptbookRequest] =
        useState<CreatePromptbookRequest>();

    const {
        mutate: createPromptbook,
        isError: createPromptbookError,
        reset,
        error,
        isLoading: isCreatePromptbookOperationInProgress,
    } = useCreatePromptbook();

    const {mutate: editPromptbook} = useEditPromptbook();
    const [firstRender, setFirstRender] = useState<boolean>(true);

    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

    const {
        errors: validationErrors,
        isError: isValidationError,
        validateForm,
        getParamsFromPrompts,
    } = useCreatePromptbookFormValidations(createPromptbookRequest);

    // Wait until the fetch prompts is completed
    const promptsDataLoading = useMemo(() => {
        return getPromptsResult?.some((result) => result.isLoading);
    }, [getPromptsResult]);

    const skillsetInvocationsDataLoading = useMemo(() => {
        return getEvaluationSkillInvocationsResult.some((result) => result.isLoading);
    }, [getEvaluationSkillInvocationsResult]);

    // Wait until the fetch evaluations is completed
    const evaluationsDataLoading = useMemo(() => {
        return getEvaluationsResult?.some((result) => result.isLoading);
    }, [getEvaluationsResult]);

    useEffect(() => {
        // validating for required fields based on mode
        const isEditOrDuplicate =
            mode === PromptbookOperationMode.Duplicate || mode === PromptbookOperationMode.Edit;
        if (isEditOrDuplicate && !promptbook) {
            const errorString = t('InvalidConfiguration', {mode});
            throw new Error(errorString);
        } else if (isEditOrDuplicate) {
            const promptbookToUpdate: CreatePromptbookRequest = {
                ...createPromptbookRequest,
                name: promptbook?.name!,
                description: promptbook?.description!,
                prompts: promptbook?.prompts!,
                promptbookinputs: promptbook?.promptbookinputs!,
                visibility:
                    mode == PromptbookOperationMode.Edit
                        ? promptbook?.visibility!
                        : PromptbookVisibility.Private,
                tags: promptbook?.tags,
                workspaceId: promptbook?.workspaceId!,
            };
            mode == PromptbookOperationMode.Edit
                ? setSelectedVisibilityScope(promptbook?.visibility!)
                : setSelectedVisibilityScope(PRIVATE_SCOPE);
            setCreatePromptbookRequest(promptbookToUpdate);
        }
    }, [mode, promptbook]);

    const promptsData = useMemo(() => {
        if (mode !== PromptbookOperationMode.Create) {
            return [];
        }
        if (!promptsDataLoading) {
            if (getPromptsResult.every((result) => result.isSuccess)) {
                return getPromptsResult
                    .filter(
                        (result) =>
                            result.data !== undefined &&
                            (isDirectSkillInvocationEnabled
                                ? true
                                : result.data.promptType !==
                                  (PromptType.Skill || PromptType.SkillDirect)),
                    )
                    .map((result) => ({...result.data} as Prompt));
            }
        }
        return [];
    }, [mode, promptsDataLoading, getPromptsResult]);

    // LastEvaluationIds are set based on the promptIds that are passed from the props
    // LastEvaluationIds are used to fetch the latest evaluations for corresponding prompts
    // This useEffect is used to set the lastEvaluationIds based on the getPromptsResult
    useEffect(() => {
        if (!promptsDataLoading && promptsData.length !== 0) {
            const promptAndEvaluationIds: [string, string][] = [];

            for (let idx = 0; idx < promptIds.length; idx++) {
                const promptId = promptIds[idx];
                const matchingPrompt = promptsData.find((prompt) => prompt.promptId === promptId);
                if (!isEmpty(matchingPrompt) && matchingPrompt?.latestEvaluationId !== 'null') {
                    promptAndEvaluationIds.push([
                        promptId,
                        matchingPrompt?.latestEvaluationId as string,
                    ]);
                }
            }

            // Use the promptAndEvaluationIds as needed
            setPromptEvaluationIds(promptAndEvaluationIds);
        }
    }, [promptsData]);

    const evaluationsData = useMemo(() => {
        if (mode !== PromptbookOperationMode.Create) {
            return [];
        }
        if (!evaluationsDataLoading) {
            if (getEvaluationsResult.every((result) => result.isSuccess)) {
                return getEvaluationsResult
                    .filter((result) => result.data !== undefined)
                    .map((result) => result.data as Evaluation);
            }
        }
        return [];
    }, [mode, evaluationsDataLoading, getEvaluationsResult]);

    const processInputs = (
        evaluationsData: Evaluation[],
        promptsData: Prompt[],
    ): {promptbookPrompts: PromptbookPrompts[]; pbInputs: PromptbookInputDescriptor[]} => {
        const inputCountMap: Map<string, number> = new Map();
        const inputSuffixMap: Map<string, number> = new Map();

        // For each evaluation, we are checking if the promptSnapshot has inputs and then we are adding the inputs to the inputCountMap
        // If the input is already present in the inputCountMap, we are incrementing the count
        // If the input is not present in the inputCountMap, we are adding it to the inputCountMap
        // If the input is already present in the inputSuffixMap, we are incrementing the count
        // If the input is not present in the inputSuffixMap, we are adding it to the inputSuffixMap
        // SuffixMap is used to track if multiple parameters have same name and we add a numeral sufix
        // Prefix Map is used to add prefix to the input parameters if there are prompts with ProductName
        if (isPromptbookAutoParametrizationEnabled) {
            evaluationsData.forEach((evaluation) => {
                if (evaluation.promptSnapshot?.inputs) {
                    let paramPrefix = '';
                    Object.entries(evaluation.promptSnapshot.inputs).forEach(([key, value]) => {
                        if (key === 'ProductName') {
                            paramPrefix = value;
                        }
                    });
                    Object.entries(evaluation.promptSnapshot.inputs).forEach(([key, value]) => {
                        let splitValue = [value];
                        if (key.toLocaleLowerCase() === 'id') {
                            splitValue = value.split('\n');
                        }
                        for (const val of splitValue) {
                            key = paramPrefix.toLocaleLowerCase() + key;
                            const searchString: string =
                                key.toLocaleLowerCase() + val.toLocaleLowerCase();

                            const hasString = inputCountMap.has(searchString);
                            if (hasString) {
                                let count = (inputCountMap.get(searchString) ?? 0) + 1;
                                // inputCountMap.set(searchString, count);
                            } else {
                                if (inputSuffixMap.has(key.toLocaleLowerCase())) {
                                    inputCountMap.set(
                                        searchString,
                                        (inputSuffixMap.get(key.toLocaleLowerCase()) ?? 0) + 1,
                                    );
                                    inputSuffixMap.set(
                                        key.toLocaleLowerCase(),
                                        (inputSuffixMap.get(key.toLocaleLowerCase()) ?? 0) + 1,
                                    );
                                } else {
                                    inputCountMap.set(searchString, 1);
                                    inputSuffixMap.set(key.toLocaleLowerCase(), 1);
                                }
                            }
                        }
                    });
                }
            });
        }

        // For each prompt, we are checking if the prompt has inputs and then we are adding the inputs to the inputCountMap
        // We are setting the input parameters to the prompt content
        // We are doing so by replacing the input parameters with <inputparam> in the prompt content
        // Plugins are initialized to an empty list here. They will populated onces the skill invocations are fetched.
        const promptbookPrompts = promptsData
            .filter((p) => p !== undefined)
            .map((prompt, idx) => {
                if (prompt !== undefined) {
                    const matchingEvaluation = evaluationsData.find(
                        (evaluation) => evaluation.promptId === prompt.promptId,
                    );
                    if (isPromptbookAutoParametrizationEnabled && matchingEvaluation) {
                        if (matchingEvaluation.promptSnapshot?.inputs) {
                            let paramPrefix = '';
                            Object.entries(matchingEvaluation.promptSnapshot.inputs).forEach(
                                ([key, value]) => {
                                    if (key === 'ProductName') {
                                        paramPrefix = value;
                                    }
                                },
                            );
                            Object.entries(matchingEvaluation.promptSnapshot.inputs).forEach(
                                ([key, value]) => {
                                    let splitValue = [value];
                                    if (key.toLocaleLowerCase() === 'id') {
                                        splitValue = value.split('\n');
                                    }
                                    for (const val of splitValue) {
                                        let parametrizedKey = key;
                                        let regex = new RegExp(
                                            `(?!<[^>]*?)${escapeRegExp(val)}(?![^<]*?>)`,
                                            'g',
                                        );
                                        if (
                                            parametrizedKey !== 'ProductName' &&
                                            prompt.content &&
                                            prompt.content.length !== String(val).length &&
                                            prompt.content.match(regex)
                                        ) {
                                            let suffix = '';
                                            const searchString: string =
                                                paramPrefix.toLocaleLowerCase() +
                                                parametrizedKey.toLocaleLowerCase() +
                                                val.toLocaleLowerCase();
                                            const inputMapSearchString: string =
                                                paramPrefix.toLocaleLowerCase() +
                                                parametrizedKey.toLocaleLowerCase();
                                            if (
                                                (inputSuffixMap.get(inputMapSearchString) ?? 0) > 1
                                            ) {
                                                if (inputCountMap.has(searchString)) {
                                                    suffix = (
                                                        inputCountMap.get(searchString) ?? ''
                                                    ).toString();
                                                    parametrizedKey = parametrizedKey + suffix;
                                                }
                                            }
                                            if (paramPrefix === '') {
                                                prompt.content = prompt.content.replace(
                                                    regex,
                                                    `<${parametrizedKey}>`,
                                                );
                                            } else {
                                                prompt.content = prompt.content.replace(
                                                    regex,
                                                    `<${paramPrefix}_${parametrizedKey}>`,
                                                );
                                                parametrizedKey =
                                                    paramPrefix + '_' + parametrizedKey;
                                            }

                                            prompt.inputs = prompt.inputs ?? {};
                                            prompt.inputs[parametrizedKey] = val;
                                        }
                                    }
                                },
                            );
                        }
                    }
                    return {
                        ...prompt,
                        sequenceNumber: idx,
                        plugins: prompt.skillsets,
                    } as PromptbookPrompts;
                }
                return {} as PromptbookPrompts;
            });

        const promptInputs = Array.from(
            new Set(
                promptbookPrompts
                    .filter(
                        (prompt) =>
                            prompt !== undefined &&
                            prompt.promptType === PromptType.Prompt &&
                            prompt.inputs,
                    )
                    .flatMap((prompt) => Object.keys(prompt.inputs ?? [])),
            ),
        );

        const pbInputs = promptInputs.map(
            (input) => ({name: input, description: input} as PromptbookInputDescriptor),
        );

        return {promptbookPrompts, pbInputs};
    };

    // This only runs in create mode as plugins initialization is for create mde only.
    // We wait for evaluation skill invocations data to be fetched for plugin initialization.
    useMemo(() => {
        if (mode !== PromptbookOperationMode.Create) {
            return;
        }
        if (
            !skillsetInvocationsDataLoading &&
            !pluginsInitialized &&
            getEvaluationSkillInvocationsResult.length > 0 &&
            getEvaluationSkillInvocationsResult.every((result) => result.isSuccess)
        ) {
            // We iterate through each prompt present in promptsData (variable containing list of Prompts selected),
            // find the corresponding skill invocations list.
            const promptbookPrompts = promptsData
                .filter((p) => p !== undefined)
                .map((prompt, idx) => {
                    if (prompt !== undefined) {
                        delete prompt.latestEvaluationId;
                        prompt.sessionId = '';

                        let plugins: Set<string> = new Set();
                        prompt.skillsets?.forEach((skillset) => plugins.add(skillset));
                        getEvaluationSkillInvocationsResult.forEach((result) => {
                            result.data?.value?.forEach((invocation) => {
                                // Take only those skill invocations which have skills present in skillsets list
                                // as only these skillsets are meant to be displayed on UI and others are internal.
                                // List of all such skillsets is set in plugins list for each prompt.
                                if (invocation.promptId === prompt.promptId) {
                                    const matchedSkillset = skillsets?.value.find(
                                        (skillset) => skillset.name === invocation.skillsetName,
                                    );
                                    if (
                                        matchedSkillset &&
                                        matchedSkillset.category in SkillsetCategory
                                    ) {
                                        plugins.add(matchedSkillset.name);
                                    }
                                }
                            });
                        });

                        return {
                            ...prompt,
                            sequenceNumber: idx,
                            plugins: Array.from(plugins),
                        } as PromptbookPrompts;
                    }
                    return {} as PromptbookPrompts;
                });

            // We now update createPromptbookRequest state with prompts list containing plugins. While doing it, we
            // need to ensure that the parameterized content of prompts is not lost. Thus, we find the prompt with matching
            // sequence number from previous state of createPromptbookRequest and copy the content which could been parameterized.
            setCreatePromptbookRequest((prevState) => {
                const updatedPrompts = promptbookPrompts.map((prompt) => {
                    const matchingPrompt = prevState?.prompts?.find(
                        (p) => p.sequenceNumber === prompt.sequenceNumber,
                    );
                    return {
                        ...prompt,
                        content: matchingPrompt?.content || '',
                        promptId: '',
                        sessionId: '',
                        lastCompletedEvaluationId: '',
                        evaluationId: '',
                    };
                });

                return {
                    name: prevState?.name || '',
                    description: prevState?.description || '',
                    prompts: updatedPrompts,
                    promptbookinputs: prevState?.promptbookinputs || [],
                    tags: [],
                    visibility: prevState?.visibility || PromptbookVisibility.Private,
                    workspaceId: prevState?.workspaceId || '',
                };
            });

            setpluginsInitialized(true);
        }
    }, [skillsetInvocationsDataLoading, getEvaluationSkillInvocationsResult]);

    useEffect(() => {
        if (mode !== PromptbookOperationMode.Create) {
            return;
        }
        // input parameters are added to the promptbook inputs based on the prompts
        // Suffix map is used to add the suffix to the input parameters if there are multiple parameters with the same name
        // If the input parameters has ProductName, we add is as a prefix to every param in that prompt
        if (promptsData && evaluationsData.length !== 0 && firstRender) {
            // We set the getEvaluationSkillInvocationRequest based on the prompts and evaluations data
            // for all prompts which have lastCompletedEvaluationId set. As skillset indetification can be done only
            // for prompts with completed evaluations.
            const filteredPrompts = promptsData.filter(
                // Ignore prompts which have lastCompletedEvaluationId as null or undefined as we cannot determine skill invocations for them.
                (prompt) => prompt.lastCompletedEvaluationId,
            );
            const filteredPromptIds = filteredPrompts.map((prompt) => prompt.promptId);
            const filteredEvaluationIds = filteredPrompts.map(
                (prompt) => prompt.lastCompletedEvaluationId!,
            );

            setGetEvaluationSkillInvocationRequest({
                sessionId,
                promptIds: filteredPromptIds,
                evaluationIds: filteredEvaluationIds,
                enabled: true,
            });

            const {promptbookPrompts, pbInputs} = processInputs(evaluationsData, promptsData);
            setCreatePromptbookRequest({
                name: '',
                description: '',
                prompts: promptbookPrompts,
                promptbookinputs: pbInputs,
                tags: [],
                visibility: PromptbookVisibility.Private,
                workspaceId: '',
            });

            setFirstRender(false);
        }
    }, [mode, promptsData, evaluationsData]);

    // Every time createPromptbookRequest changes, extract the plugins list from prompts
    // and create a set containing skillset name, icon and display name so that they can be
    // rendered inside PromptbookPlugins component.
    useMemo(() => {
        if (createPromptbookRequest && !skillsetsLoading) {
            let plugins = createPromptbookRequest.prompts.reduce((acc, prompt) => {
                prompt.plugins?.forEach((plugin) => {
                    const matchedSkillset = skillsets?.value.find(
                        (skillset) => skillset.name === plugin,
                    );
                    if (matchedSkillset) {
                        acc.push({
                            skillset: matchedSkillset.name,
                            icon: matchedSkillset.icon || '',
                            displayName: matchedSkillset.displayName || '',
                        });
                    }
                });
                return acc;
            }, [] as {skillset: string; icon: string; displayName: string}[]);
            setPlugins(plugins);
        }
    }, [createPromptbookRequest, skillsets]);

    useEffect(() => {
        if (validationErrors) {
            setFormErrors(validationErrors);
        }
    }, [validationErrors]);

    const handleSubmit = (forceDuplicate = false) => {
        // Perform validations before submitting the form. The custom hook will set the validationErrors which will change(set) the state.
        // Depending on the error field, we are showing it on the form with the message from the validation layer.
        if (!validateForm()) {
            return;
        }

        if (createPromptbookRequest) {
            const newPromptbook: CreatePromptbookRequest = {
                ...createPromptbookRequest,
                workspaceId: workspace?.workspaceId ?? '',
                prompts: createPromptbookRequest.prompts.map((p, index) => {
                    return {...p, sequenceNumber: index};
                }),
            };

            if (mode === PromptbookOperationMode.Edit && !forceDuplicate) {
                const editPromptbookReq: EditPromptbookRequest = {
                    ...newPromptbook,
                    promptbookId: promptbook?.promptbookId!,
                };

                editPromptbook(editPromptbookReq, {
                    onSuccess: (promptbook: PromptbookDescriptor) => {
                        handleClose(PromptbookCloseResult.success, promptbook);
                    },
                    onError: (error: any) => {
                        // Since the backend code uses Problem object which has {title, detail, status} properties,
                        // we are checking if the error is of type ApiError and then extracting the detail property from it.
                        if (error instanceof ApiError && error.response) {
                            error.response.text().then((e) => {
                                setApiErrorMessage(
                                    `${
                                        JSON.parse(e).detail ?? JSON.parse(e).value ?? e.toString()
                                    }`,
                                );
                            });
                        } else {
                            setApiErrorMessage(error.toString());
                        }
                    },
                });
            } else {
                newPromptbook.name =
                    mode === PromptbookOperationMode.Duplicate ||
                    mode === PromptbookOperationMode.Edit
                        ? newPromptbook.name + ' (Copy)'
                        : newPromptbook.name;
                createPromptbook(newPromptbook, {
                    onSuccess: (promptbook: PromptbookDescriptor) => {
                        handleClose(PromptbookCloseResult.success, promptbook);
                    },
                    onError: (error: any) => {
                        // Since the backend code uses Problem object which has {title, detail, status} properties,
                        // we are checking if the error is of type ApiError and then extracting the detail property from it.
                        if (error instanceof ApiError && error.response) {
                            if (error.response?.status === 403) {
                                const errorMessage = t('Errors.NoPermissionForFeature');
                                setApiErrorMessage(errorMessage);
                            } else {
                                error.response.text().then((e) => {
                                    setApiErrorMessage(
                                        `${
                                            JSON.parse(e).detail ??
                                            JSON.parse(e).message ??
                                            JSON.parse(e).value ??
                                            e.toString()
                                        }`,
                                    );
                                });
                            }
                        } else {
                            setApiErrorMessage(error.toString());
                        }
                    },
                });
            }
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (createPromptbookRequest) {
            const newObj = {...createPromptbookRequest, [event.target.name]: event.target.value};
            setCreatePromptbookRequest(newObj);
        }
    };

    const handleDescriptionChange = (
        event: ChangeEvent<HTMLTextAreaElement>,
        data: TextareaOnChangeData,
    ) => {
        if (createPromptbookRequest) {
            const newObj = {...createPromptbookRequest, [event.target.name]: data.value};
            setCreatePromptbookRequest(newObj);
        }
    };

    const handleInputsFormChange = (formData: PromptbookInputDescriptor[]) => {
        if (createPromptbookRequest) {
            createPromptbookRequest.promptbookinputs = formData;
            setCreatePromptbookRequest({...createPromptbookRequest});
        }
    };

    const handleTagsChange = (formData: PromptbookTag[]) => {
        if (createPromptbookRequest) {
            setCreatePromptbookRequest({...createPromptbookRequest, tags: formData});
        }
    };

    const handleClose = (
        createResult: PromptbookCloseResult,
        promptbookDefn?: PromptbookDescriptor,
    ) => {
        if (createResult === PromptbookCloseResult.success) {
            onClose?.(createResult, promptbookDefn);
            // close the view model window
            if (mode == PromptbookOperationMode.Edit || mode == PromptbookOperationMode.Duplicate) {
                onSuccess?.();
            }
        } else {
            onClose?.(createResult);
        }
    };

    const handlePromptListDataChange = (promptDefn: PromptbookPrompts[]) => {
        if (!createPromptbookRequest) {
            return;
        }

        createPromptbookRequest.prompts = promptDefn;
        const parametersInPrompts = getParamsFromPrompts?.(promptDefn);

        const newInputs = parametersInPrompts.map(
            (param) => ({name: param, description: param} as PromptbookInputDescriptor),
        );

        createPromptbookRequest.promptbookinputs = newInputs;
        setCreatePromptbookRequest({...createPromptbookRequest});
    };

    // Remove prompt inputs if direct skill invocation is enabled since they are generated when promptbooks are applied
    const removePromptInputs = (prompts: PromptbookPrompts[]) => {
        if (isDirectSkillInvocationEnabled) {
            prompts.forEach((prompt) => {
                prompt.inputs = {};
            });
        }
        return prompts;
    };

    return (
        <Dialog
            modalType="modal"
            open={open}
            onOpenChange={(event, data) => {
                if (data.type === 'escapeKeyDown' || data.type === 'backdropClick') {
                    handleClose(PromptbookCloseResult.closed);
                }
            }}
        >
            <DialogSurface
                aria-describedby={undefined}
                className={mergeClasses(classes.root, scrollClasses.colorNeutralBackground1)}
            >
                {/* When the promptData has been fetched and createPromptbook operation is not in progress, show the create form */}
                {!promptsDataLoading &&
                    !evaluationsDataLoading &&
                    !isCreatePromptbookOperationInProgress &&
                    !skillsetsLoading &&
                    pluginsInitialized &&
                    createPromptbookRequest && (
                        <DialogBody className={classes.fullSize}>
                            <DialogTitle
                                action={
                                    <DialogTrigger action="close" disableButtonEnhancement>
                                        <Button
                                            data-testid="close-button"
                                            appearance="subtle"
                                            aria-label={tCommon('ButtonLabels.Close')}
                                            icon={<DismissIcon />}
                                            onClick={() =>
                                                handleClose(PromptbookCloseResult.closed)
                                            }
                                        ></Button>
                                    </DialogTrigger>
                                }
                            >
                                {mode == PromptbookOperationMode.Create
                                    ? t('CreatePromptBookTitle')
                                    : mode == PromptbookOperationMode.Edit
                                    ? t('CreateForm.EditPromptBookTitle')
                                    : t('CreateForm.DuplicatePromptBookTitle')}
                            </DialogTitle>
                            <DialogContent className={scrollClasses.colorNeutralBackground1}>
                                {createPromptbookError && (
                                    <MessageBar intent="error">
                                        <MessageBarBody>{apiErrorMessage}</MessageBarBody>
                                    </MessageBar>
                                )}
                                <form className={classes.form}>
                                    {mode == PromptbookOperationMode.Edit && (
                                        <MessageBar intent="info">
                                            <MessageBarBody data-testid="edit-warning">
                                                {t('ChangePromptTextWarning')}
                                            </MessageBarBody>
                                        </MessageBar>
                                    )}
                                    <Field
                                        id="name"
                                        label={t('NameFieldLabel')}
                                        required={true}
                                        validationState={
                                            formErrors && formErrors.name ? 'error' : 'none'
                                        }
                                        validationMessage={
                                            formErrors && formErrors.name
                                                ? formErrors.name
                                                : undefined
                                        }
                                    >
                                        <Input
                                            type="text"
                                            name="name"
                                            defaultValue={createPromptbookRequest.name}
                                            key="name"
                                            autoFocus
                                            size="medium"
                                            appearance="outline"
                                            className={classes.textareaWrapper}
                                            placeholder={t('NameInputPlaceholder')}
                                            onChange={(event) => handleInputChange(event)}
                                        />
                                    </Field>
                                    <Field
                                        id="tags"
                                        label={t('TagsFieldLabel')}
                                        validationState={
                                            formErrors && formErrors.tags ? 'error' : 'none'
                                        }
                                        validationMessage={
                                            formErrors && formErrors.tags
                                                ? formErrors.tags
                                                : undefined
                                        }
                                    >
                                        <div className={classes.inputFields}>
                                            <PromptbookTags
                                                edit={true}
                                                formData={[...(createPromptbookRequest.tags ?? [])]}
                                                onFormDataChange={handleTagsChange}
                                            />
                                        </div>
                                    </Field>
                                    <Field
                                        id="description"
                                        label={t('DescriptionFieldLabel')}
                                        required={true}
                                        validationState={
                                            formErrors && formErrors.description ? 'error' : 'none'
                                        }
                                        validationMessage={
                                            formErrors && formErrors.description
                                                ? formErrors.description
                                                : undefined
                                        }
                                    >
                                        <Textarea
                                            key="description"
                                            name="description"
                                            defaultValue={createPromptbookRequest.description}
                                            size="medium"
                                            appearance="outline"
                                            className={classes.textareaWrapper}
                                            placeholder={t('DescriptionInputPlaceholder')}
                                            onChange={(event, data) =>
                                                handleDescriptionChange(event, data)
                                            }
                                        />
                                    </Field>
                                    <Field id="plugins" label={t('PluginsFieldLabel')} tabIndex={0}>
                                        <PromptbookPlugins
                                            shouldOverflow={false}
                                            plugins={plugins}
                                        />
                                    </Field>
                                    <Field
                                        id="prompts"
                                        label={t('PromptsLabelContent')}
                                        validationState={
                                            formErrors && formErrors.prompts ? 'error' : 'none'
                                        }
                                        validationMessage={
                                            formErrors && formErrors.prompts
                                                ? formErrors.prompts
                                                : undefined
                                        }
                                    >
                                        <div
                                            className={mergeClasses(
                                                classes.flexContainerRow,
                                                classes.promptList,
                                            )}
                                        >
                                            <div>{t('PromptsInfoContent')}</div>
                                            {createPromptbookRequest.prompts && (
                                                <PromptbookPromptList
                                                    isModal={true}
                                                    promptDefns={removePromptInputs(
                                                        createPromptbookRequest.prompts,
                                                    )}
                                                    onPromptDataChange={handlePromptListDataChange}
                                                />
                                            )}
                                        </div>
                                    </Field>
                                    {!isDirectSkillInvocationEnabled && (
                                        <Field
                                            id="inputs"
                                            label={t('PromptbookInputPlaceholder')}
                                            validationState={
                                                formErrors && formErrors.promptbookinputs
                                                    ? 'error'
                                                    : 'none'
                                            }
                                            validationMessage={
                                                formErrors && formErrors.promptbookinputs
                                                    ? formErrors.promptbookinputs
                                                    : undefined
                                            }
                                        >
                                            <div className={classes.inputFields}>
                                                <div>{t('PromptbookInputsSummary')}</div>
                                                <PromptbookInputsForm
                                                    edit={false}
                                                    showDelete={true}
                                                    formData={[
                                                        ...createPromptbookRequest.promptbookinputs,
                                                    ]}
                                                    onFormDataChange={handleInputsFormChange}
                                                />
                                            </div>
                                        </Field>
                                    )}
                                    <Field id="visibility" label={t('WhoCanUsePromptbook')}>
                                        <Dropdown
                                            value={
                                                visibilitySelectionStrings[selectedVisibilityScope]
                                            }
                                            selectedOptions={[
                                                visibilitySelectionStrings[selectedVisibilityScope],
                                            ]}
                                            onOptionSelect={(_, data) => {
                                                setSelectedVisibilityScope(
                                                    data.optionValue as string,
                                                );
                                                const newObj = {
                                                    ...createPromptbookRequest,
                                                    visibility:
                                                        data.optionValue as PromptbookVisibility,
                                                };
                                                setCreatePromptbookRequest(newObj);
                                            }}
                                            aria-label={t('WhoCanUsePromptbook')}
                                        >
                                            {visibilityDropdownOptions.map((option) => (
                                                <Option
                                                    key={option.value}
                                                    value={option.value}
                                                    text={option.text}
                                                >
                                                    {option.text}
                                                </Option>
                                            ))}
                                        </Dropdown>
                                    </Field>
                                </form>
                            </DialogContent>
                            {mode == PromptbookOperationMode.Edit ? (
                                <>
                                    <div className={classes.editActionButtonContainer}>
                                        <DialogActions position="start">
                                            <div className={classes.editLeftButtons}>
                                                <Button
                                                    ref={createButtonRef}
                                                    className={classes.createButton}
                                                    type="submit"
                                                    onClick={() => {
                                                        handleSubmit();
                                                    }}
                                                >
                                                    {tCommon('ButtonLabels.Save')}
                                                </Button>
                                                <Button
                                                    ref={createButtonRef}
                                                    className={classes.cancelButton}
                                                    type="submit"
                                                    onClick={() => {
                                                        handleSubmit(true);
                                                    }}
                                                >
                                                    {tCommon('ButtonLabels.Duplicate')}
                                                </Button>
                                            </div>
                                        </DialogActions>
                                    </div>
                                    <div className={classes.editRightButtons}>
                                        <DialogActions>
                                            <Button
                                                data-testid="cancel-editbutton"
                                                className={classes.cancelButton}
                                                appearance="subtle"
                                                size="medium"
                                                onClick={() =>
                                                    handleClose(PromptbookCloseResult.closed)
                                                }
                                            >
                                                {t('CancelButton')}
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </>
                            ) : (
                                <DialogActions position="start">
                                    <Button
                                        ref={createButtonRef}
                                        className={classes.createButton}
                                        type="submit"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        {mode == PromptbookOperationMode.Create
                                            ? t('CreateButton')
                                            : tCommon('ButtonLabels.Duplicate')}
                                    </Button>
                                    <DialogTrigger action="close" disableButtonEnhancement>
                                        <Button
                                            data-testid="cancel-button"
                                            className={classes.cancelButton}
                                            appearance="subtle"
                                            size="medium"
                                            type="button"
                                            onClick={() =>
                                                handleClose(PromptbookCloseResult.closed)
                                            }
                                        >
                                            {t('CancelButton')}
                                        </Button>
                                    </DialogTrigger>
                                </DialogActions>
                            )}
                        </DialogBody>
                    )}

                {(promptsDataLoading ||
                    evaluationsDataLoading ||
                    isCreatePromptbookOperationInProgress ||
                    skillsetsLoading ||
                    !pluginsInitialized) && (
                    <DialogBody className={classes.fullSize}>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close" disableButtonEnhancement>
                                    <Button
                                        data-testid="close-button"
                                        appearance="subtle"
                                        aria-label={t('CloseButton')}
                                        icon={<DismissIcon />}
                                        onClick={() => handleClose(PromptbookCloseResult.closed)}
                                    ></Button>
                                </DialogTrigger>
                            }
                        ></DialogTitle>
                        <DialogContent className={classes.centerContent}>
                            {/* When promptData or evaluationsData is loading we want to show spinner with "Preparing promptbook message" */}
                            {(((promptsDataLoading || evaluationsDataLoading) &&
                                !isCreatePromptbookOperationInProgress) ||
                                skillsetsLoading ||
                                evaluationsDataLoading ||
                                !pluginsInitialized) && (
                                <Spinner
                                    data-testid="prompts-loading-spinner"
                                    labelPosition="below"
                                    size="extra-large"
                                    label={t('PreparingPromptbook')}
                                />
                            )}

                            {/* When createPromptbookOperation is in progress we want to show spinner with "Creating promptbook message" */}
                            {!(promptsDataLoading || evaluationsDataLoading) &&
                                isCreatePromptbookOperationInProgress && (
                                    <Spinner
                                        data-testid="createpromptbook-loading-spinner"
                                        labelPosition="below"
                                        size="extra-large"
                                        label={t('CreatingPromptbook')}
                                    />
                                )}
                        </DialogContent>
                    </DialogBody>
                )}
            </DialogSurface>
        </Dialog>
    );
};

export default PromptbookCreateForm;
